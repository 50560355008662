import { RouterProvider } from 'react-router-dom';
import './App.scss';
import router from './Navigation/Navigation';
import { useEffect } from 'react';

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
