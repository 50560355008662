import React from 'react'
import './BreadCrumb.scss'
function BreadCrumb({img,title}) {
  return (
    <div className="BreadCrumb">
        <div className="imgWrapper">
            <img src={img} alt={title}/>
        </div>
    </div>
  )
}

export default BreadCrumb