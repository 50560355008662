import React, {  useRef, useState } from "react";
import "./BoxSlider.scss";
import { Button } from "@mui/material";
import Slider from "react-slick";

function BoxSlider() {
  const [current,setcurrent] = useState(1)
  const slideRef = useRef();

  const gotoSlide = (index) => {
    slideRef.current.slickGoTo(index);
    setcurrent(index)
  };

  // Slider
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dataList = [
    {
        img:"/assets/Home/DJP_2257 1.png",
        content:"To be the Leader in R&D, Manufacturing and Distribution of quality and latest medical devices technology and Rare High Value Medicines in Africa.",
        BtnClick:"/about"
        
    },
    {
        img:"/assets/Home/DJP_2257 1.png",
        content:"To be the Leader in R&D, Manufacturing and Distribution of quality and latest medical devices technology and Rare High Value Medicines in Africa.",
        BtnClick:"/about"
        
    },
    {
        img:"/assets/Home/DJP_2257 1.png",
        content:"To be the Leader in R&D, Manufacturing and Distribution of quality and latest medical devices technology and Rare High Value Medicines in Africa.",
        BtnClick:"/about"
        
    },
    {
        img:"/assets/Home/DJP_2257 1.png",
        content:"To be the Leader in R&D, Manufacturing and Distribution of quality and latest medical devices technology and Rare High Value Medicines in Africa.",
        BtnClick:"/about"
        
    },
    {
        img:"/assets/Home/DJP_2257 1.png",
        content:"To be the Leader in R&D, Manufacturing and Distribution of quality and latest medical devices technology and Rare High Value Medicines in Africa.",
        BtnClick:"/about"
        
    },
    {
        img:"/assets/Home/DJP_2257 1.png",
        content:"To be the Leader in R&D, Manufacturing and Distribution of quality and latest medical devices technology and Rare High Value Medicines in Africa.",
        BtnClick:"/about"
        
    }
  ]
  return (
    <div className="BoxSlider">
      <div className="container-default">
        <div className="sliderBox">
          <Slider className="Slider" {...settings} ref={slideRef}>
            {
                dataList?.map((val,index)=>(
                    <div key={index}>
                    <div
                      className="imgBox"
                      style={{
                        background:
                          `url("${val.img}"),rgba(0, 0, 0, 0.32)`,
                      }}
                    >
                      <div className="content">
                        <p>
                            {val.content}
                        </p>
                        <Button>READ MORE</Button>
                      </div>
                    </div>
                  </div>
                ))
            }
           
          </Slider>
        </div>
        <div className="boxContainer">
          <div className="row">
            <div className="col">
              <Button className={current === 1 && "active"} onClick={() => gotoSlide(1)}>
                <img src="/assets/Home/icons/friends 1.png" alt=""/>
                <h5 className="title">Our Purpose</h5>
              </Button>
            </div>
            <div className="col" >
              <Button  className={current === 2 && "active"} onClick={() => gotoSlide(2)}>
                <img src="/assets/Home/icons/future 1.png" alt=""/>
                <h5 className="title">Our Purpose</h5>
              </Button>
            </div>
            <div className="col" >
              <Button className={current === 3 && "active"} onClick={() => gotoSlide(3)}>
                <img src="/assets/Home/icons/handshake 1.png" alt=""/>
                <h5 className="title">Our Purpose</h5>
              </Button>
            </div>
            <div className="col" >
              <Button className={current === 4 && "active"} onClick={() => gotoSlide(4)}>
                <img src="/assets/Home/icons/targeting 1.png" alt=""/>
                <h5 className="title">Our Purpose</h5>
              </Button>
            </div>
            <div className="col" >
              <Button className={current === 5 && "active"} onClick={() => gotoSlide(5)}>
                <img src="/assets/Home/icons/value 1.png" alt=""/>
                <h5 className="title">Our Purpose</h5>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxSlider;
