import React from "react";
import "./Footer.scss";
import Logo from "../../Components/Global/Logo/Logo";
import SocialMediaWidget from "../../Components/Global/SocialMediaWidget/SocialMediaWidget";
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
function Footer() {
  const location = useLocation().pathname;

  const locationActive = (val) => {
    return location === val ? "active" :"";
  };

  return (
    <div className="Footer">
      <div className="container">
        <div className="row ">
          <div className="col-4">
            <div className="aboutWidget">
              <Logo />
              <div className="content">
                <p>
                  Imagine Pharmaceuticals Limited (Imagine Pharma) is a Ugandan
                  registered company with Imagine Design and Build Limited as
                  the parent company. "The essence of our culture is to build
                  best practices that meet global standards in every aspect of
                  our business." We aspire to be the Biggest in active
                  Manufacturing, R&D and Distribution of IVDs, Medical supplies,
                  Preventive and Palliative care medicines and devices that meet
                  the needs of the region.
                </p>
                <SocialMediaWidget />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="navWidget">
              <h3 className="widgetTitle">Usefull Link</h3>
              <ul className="menus">
                <li>
                  <Button>Home</Button>
                </li>
                <li>
                  <Button>About</Button>
                </li>
                <li>
                  <Button>Contact Us</Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="navWidget">
              <h3 className="widgetTitle">Usefull Link</h3>
              <ul className="menus">
                <li>
                  <Button>Innovation</Button>
                </li>
                <li>
                  <Button>Milestones</Button>
                </li>
                <li>
                  <Button>News Room</Button>
                </li>
                <li>
                  <Button>Warehouse</Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="navWidget">
              <h3 className="widgetTitle">Usefull Link</h3>
              <ul className="menus">
                <li className={locationActive("/privacy-policy")}>
                  <Link to={"/privacy-policy"}>
                    <Button>Privacy Police</Button>
                  </Link>
                </li>
                <li className={locationActive("/term-condition")}>
                <Link to={"/term-condition"}>
                  <Button>Terms & Condition</Button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="navWidget">
              <h3 className="widgetTitle">About us</h3>
              <ul className="menus">
                <li>
                  <Button>Our Team</Button>
                </li>
                <li className={locationActive("/about-us")}>
                  <Link to={"/about-us"}>
                    <Button>About Us</Button>
                  </Link>
                </li>
                <li className={locationActive("/contact-us")}>
                  <Link to={"/contact-us"}>
                    <Button>Contact Us</Button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>© Imagine Pharma, Inc. 2024</p>
      </div>
    </div>
  );
}

export default Footer;
