import React from 'react'
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb'
import BlogLayout from '../../Components/Global/BlogLayout/BlogLayout'
import './NewsRoom.scss'
import { Button, Pagination } from '@mui/material'
function NewsRoom() {
  return (
    <div className="NewsRoomPage">
        <BreadCrumb img={"/assets/BreadCrumb/newsroom.png"} alt=""/>
        <h1 className="headingTitle">News Room</h1>
       <div className="container mt-5">
       {/* BtnWrapper */}
        <div className="BtnWrapper">
            <ul className="menus">
              <li className="menu active"><Button>Filter</Button></li>
              <li className="menu "><Button>View All</Button></li>
              <li className="menu "><Button>Press Releases</Button></li>
              <li className="menu "><Button>Media Coverage</Button></li>
              <li className="menu "><Button>Events</Button></li>
              <li className="menu "><Button>Publication</Button></li>
            </ul>
        </div>
       <div className="contentWrapper">
            <BlogLayout img={"/assets/NewsRoom/1.png"} cat={"Press Release"} title={"Imagine Pharma Forms the Imagine Pharma Foundation"} content={"Imagine Pharma Forms the Imagine Pharma Foundation"} date={"Jan 31, 2024"}/>
            <BlogLayout img={"/assets/NewsRoom/1.png"} cat={"Press Release"} title={"Imagine Pharma Forms the Imagine Pharma Foundation"} content={"Imagine Pharma Forms the Imagine Pharma Foundation"} date={"Jan 31, 2024"}/>
            <BlogLayout img={"/assets/NewsRoom/1.png"} cat={"Press Release"} title={"Imagine Pharma Forms the Imagine Pharma Foundation"} content={"Imagine Pharma Forms the Imagine Pharma Foundation"} date={"Jan 31, 2024"}/>
            <BlogLayout img={"/assets/NewsRoom/1.png"} cat={"Press Release"} title={"Imagine Pharma Forms the Imagine Pharma Foundation"} content={"Imagine Pharma Forms the Imagine Pharma Foundation"} date={"Jan 31, 2024"}/>
            <BlogLayout img={"/assets/NewsRoom/1.png"} cat={"Press Release"} title={"Imagine Pharma Forms the Imagine Pharma Foundation"} content={"Imagine Pharma Forms the Imagine Pharma Foundation"} date={"Jan 31, 2024"}/>
            <BlogLayout img={"/assets/NewsRoom/1.png"} cat={"Press Release"} title={"Imagine Pharma Forms the Imagine Pharma Foundation"} content={"Imagine Pharma Forms the Imagine Pharma Foundation"} date={"Jan 31, 2024"}/>
        </div>
        {/* Pagination */}
        <div className="pagination">
            <Pagination count={10} shape="rounded" />
        </div>
       </div>
    </div>
  )
}

export default NewsRoom