import React from 'react'
import './TopBar.scss'
import { LuPhoneCall } from "react-icons/lu";
import SocialMediaWidget from '../../../Global/SocialMediaWidget/SocialMediaWidget';
import { Link } from '@mui/material';

function TopBar() {
  return (
    <div className="TopBar">
        <div className="container-default">
            <div className="d-flex justify-content-between">
                <div className="tel">
                    <span className='icon'><LuPhoneCall/></span>
                    <h5 className="title"><Link onClick={()=>window.open("tel:+256 744 111125","_self")}>+256 744 111125</Link></h5>
                </div>
               <SocialMediaWidget/>
            </div>
        </div>
    </div>
  )
}

export default TopBar