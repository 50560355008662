import React from "react";
import "./MilestoneGraph.scss";
import { Button } from "@mui/material";
function MilestoneGraph() {
    const data = [
      {
        date: 2023,
        img:["/assets/Milestone/imaginPharma.png"],
        title:"Acquired astra pharma Uganda limited 7 wholesale & Retail pharmacies in kampala and Eastern Uganda."
    },
        {
            date: 2021,
            img:["/assets/Milestone/Milestonelogos5.png","/assets/Milestone/imaginPharma.png"],
            title:"In 2021 entered An MOU with Wondfo Biotech to establish a Partnership for Investment in Sustainable Manufacturing, Distribution and Research & Development (R&D) OF Point Of Care Testing Technology (POCT) For Africa."
        },
        {
            date: 2020,
            img:["/assets/Milestone/Milestonelogos5.png"],
            title:"In 2020, Imagine Pharma Entered a Regional Technical and Trading Representation Agreement with Wondfo Biotech China."
        },
        {
            date: 2019,
            img:["/assets/Milestone/Milestonelogos6.png","/assets/Milestone/imaginPharma.png"],
            title:"Commisioned a usability study for AUTO RETRACTABLE SYRINGES in Government hospitals in Central region."
        },
        {
            date: 2019,
            img:["/assets/Milestone/Milestonelogos1.png"],
            title:"In early 2019, Imagine acquired Regional Technical and Trading Representation for CiplaQCI for DRC, Burundi, Central African Republic (CAR), Angola and Congo Brazzaville and expand expanding."
        },
        {
            date: 2018,
            img:["/assets/Milestone/Milestonelogos6.png"],
            title:"In 2018, Imagine entered into an MOU with Wealy Medical Instruments ltd, to manufacture amd distribution Auto Retractable Syringes TEchnology in Uganda."
        },
       
    ]
  return (
    <div className="MilestoneGraph">
        {
            data?.map((val,index)=>(
                <div className="boxContainer " key={index}>
                <div className="boxWrapper">
                    <div className="imgwrapper">
                    {
                        val.img?.map((src,index)=>(
                            <img key={index} src={src} alt="" />
                        ))
                    }
                    </div>
                  <div className="content">
                    <p>
                      {val.title}
                    </p>
                  </div>
                </div>
                <Button className="circleContainer">{val.date}</Button>
                <hr className="line" />
              </div>
            ))
        }
   
      
      
    </div>
  );
}

export default MilestoneGraph;
