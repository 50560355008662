import { Box, Button, Drawer } from "@mui/material";
import React, { useState } from "react";
import "./NavBar.scss";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Logo from "../../../../../Global/Logo/Logo";
import { IoClose } from "react-icons/io5";
import { FaCloudDownloadAlt } from "react-icons/fa";


function NavBar() {
  const { pathname } = useLocation();
  const [open, setopen] = useState(false);

  const content = (
    <ul className="menu">
      <li className={`menu-item ${pathname === "/" && "active"}`}>
        <Link to={"/"} onClick={() => setopen(false)}>
          <Button>Home</Button>
        </Link>
      </li>
      <li className={`menu-item ${pathname === "/innovation" && "active"}`}>
        <Link to={"innovation"} onClick={() => setopen(false)}>
          <Button>Innovation</Button>
        </Link>
      </li>
      <li className={`menu-item ${pathname === "/milestones" && "active"}`}>
        <Link to={"milestones"} onClick={() => setopen(false)}>
          <Button>Milestones</Button>
        </Link>
      </li>
      <li className={`menu-item ${pathname === "/warehouse" && "active"}`}>
        <Link to={"warehouse"} onClick={() => setopen(false)}>
          <Button>Warehouse</Button>
        </Link>
      </li>
      {/* <li className={`menu-item ${pathname === "/news-room" && "active"}`}>
        <Link to={"news-room"} onClick={() => setopen(false)}>
          <Button>News Room</Button>
        </Link>
      </li> */}
      <li className={`menu-item ${pathname === "/about-us" && "active"}`}>
        <Link to={"about-us"} onClick={() => setopen(false)}>
          <Button>About Us</Button>
        </Link>
      </li>
      <li className={`menu-item ${pathname === "/contact-us" && "active"}`}>
        <Link to={"/contact-us"} onClick={() => setopen(false)}>
          <Button>Contact Us</Button>
        </Link>
      </li>
      <li className={`menu-item menuBtn`}>
        <a href={"/assets/IMAGINE PHARMACEUTICALS LTD COMPANY PROFILE 2021.pdf"} target="_blank" onClick={() => setopen(false)}>
          <Button >Our Profile <FaCloudDownloadAlt/></Button>
        </a>
      </li>
    </ul>
  );
  return (
    <div className="NavBar container-default">
      {content}
      {open ? (
        <Button className={`menubar active`} onClick={() => setopen(false)}>
          <IoClose />
        </Button>
      ) : (
        <Button className={`menubar `} onClick={() => setopen(true)}>
          <FaBars />
        </Button>
      )}
      <Drawer open={open} onClose={() => setopen(false)} className="sidebar">
        <Box sx={{ width: 250 }}>
          <Logo />
          {content}
        </Box>
      </Drawer>
    </div>
  );
}

export default NavBar;
