import React from 'react'
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb'
import './Milestones.scss'
import MilestoneGraph from './Components/MilestoneGraph/MilestoneGraph'
function Milestones() {
  return (
    <div className="Milestones">
      <BreadCrumb img={"/assets/BreadCrumb/2382 1.png"} />
      <h1 className="headingTitle">Milestones</h1>
      <div className="container">
       <MilestoneGraph/>
      <div className="content mt-5">
        <p><b>Since incorporation in 2018 Imagine  Pharma </b> has undergone various  phases of Strategy shifts, engagements and consolidation. Future  Considerations on strategic mergers  and acquisitions are inevitable and  these will form the true identity this  wholly owned member of Imagine  Group of companies. Imagine Pharmaceutical limited is  Growing and will continue to grow  from strength-to-strength to make a  mark in the global pharmaceutical  space. The past two years, we have gained momentum in each of these thrust areas with important gains in market  reach, relationship building and Market research - especially in terms of creating essential capabilities for  Technology Transfer challenges ahead. With this, all of us are committed to explore our true potential and  face the challenges on our way to attain important milestones.</p>
      </div>
      <h2 className="title text-center mt-5">PARTNERSHIPS AND ALLIANCES</h2>
      <div className="logoContainer mt-5">
          <img src="/assets/Milestone/Milestonelogos1.png" alt="" />
          <img src="/assets/Milestone/Milestonelogos2.png" alt="" />
          <img src="/assets/Milestone/Milestonelogos3.png" alt="" />
          <img src="/assets/Milestone/Milestonelogos4.png" alt="" />
          <img src="/assets/Milestone/Milestonelogos5.png" alt="" />
          <img src="/assets/Milestone/Milestonelogos6.png" alt="" />
      </div>
      </div>
      
    </div>
  )
}

export default Milestones