import React from 'react'
import './Logo.scss'
import { Link } from 'react-router-dom';

function Logo({className,type}) {
  const logoUrl = type === 'bg' ? "logo-opacity.png" : '/logo.png';
  return (
    <div className={`Logo ${className}`}>
        <Link to={"/"}><img src={`/assets/${logoUrl}`} alt=''/></Link>
    </div>
  )
}

export default Logo