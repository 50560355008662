import React from 'react'
import './OurTeam.scss'
import BreadCrumb from "../../Components/Global/BreadCrumb/BreadCrumb";

function  OurTeam() {
  return (
    <div className="OurTeam">
    <BreadCrumb
      img={"/assets/BreadCrumb/DJP_2419 1.png"}
      title={"About us"}
    />
    <h1 className="headingTitle">Our Team</h1>
    <div className="container mt-5 pt-3">
      <div className="sectionAbout p-5" style={{border:"5px dotted rgba(0,0,0,.4)"}}>
        <div className="row align-items-center">
          <div className="col-md-7">
            <div className="contentBox">
              <p>
              <b>John Kamili </b> is charged with Corporate and Technical Direction of Imagine Pharma. John’s extensive experience in Manufacturing and Technology transfer in Low and Middle income countries.
              Imagine pharma leverages of Dr. John’s longterm relationships build with multiple institutions within the sector both governmental and none governmental, local and international
              </p>
            </div>
          </div>
          <div className="col-md-5 mt-3">
            <div className="doubleImgBox text-center">
              <img src={"/assets/Our-Team/director.png"} className='w-50 rounded-5' alt="" />
              <h3 className="title" style={{fontSize:23,marginTop:20,color:"rgba(0,0,0,.4)"}}>CHAIRMAN BOARD OF DIRECTORS</h3>
            </div>
          </div>
        </div>
      </div>
      {/* DIRECTOR AND MESSAGE */}
      <div className="sectionAbout mt-5 p-5" style={{border:"5px dotted rgba(0,0,0,.4)"}}>
        <div className="row align-items-center">
        <div className="col-md-5">
            <div className="doubleImgBox text-center">
              <img src={"/assets/Our-Team/ceo.png"} className='w-50 rounded-5' alt="" />
              <h3 className="title" style={{fontSize:23,marginTop:20,color:"rgba(0,0,0,.4)"}}>MANAGING DIRECTOR AND CEO</h3>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <div className="contentBox">
              <p>
             <b>Abeine Abdul</b> is charged with investment strategy Development and Strategy implementation at Imagine Pharma . Abeine has extensive experience in Develop- ment Management, Business Structuring and Business health Monitoring, Manu- facturing, Operator Acquisition, Negotiations, Stake holder and Contracts Manage- ment.
              Imagine pharma leverages Mr. Abeine’s longterm Business Leadership Experi- ence, Enterprising Acumen and Strategic Relationships at various levels of Public and the Private sector
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="BtnArea w-100 text-center mt-5 mb-5">
    </div>
  </div>
  )
}

export default  OurTeam