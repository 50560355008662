import React from 'react'
import HomeSlider from './Components/HomeSlider/HomeSlider/HomeSlider'
import './Home.scss'
import BoxSlider from './Components/BoxSlider/BoxSlider'
import AboutHomeWidget from './Components/About/AboutHomeWidget'
import About2 from './Components/About2/About2'
import About3 from './Components/About3/About3'
import About4 from './Components/About4/About4'
import Counter from './Components/Counter/Counter'
import Map from './Components/Map/Map'
import NewsRoom from './Components/NewsRoom/NewsRoom'
import LogoSlider from './Components/LogoSlider/LogoSlider'
function Home() {
  return (
    <div className="Home">
      <HomeSlider/>
      <AboutHomeWidget/>
      <BoxSlider/>
      <About2/>
      <About3/>
      <About4/>
      <Counter/>
      {/* <NewsRoom/> */}
      <Map/>
      <hr/>
      <LogoSlider/>
    </div>
  )
}

export default Home