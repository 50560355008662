import React from 'react'
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb'

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
    <BreadCrumb img={"/assets/BreadCrumb/DJP_2209 1.png"}/>
    <h1 className="headingTitle">Privacy Policy</h1>
   <div className="container mt-5">
   <div className="content">
      <p>
      Personally identifiable information
Dēmos, on its own and through select third party vendors, collects personally identifiable information, such as names, email addresses, mailing addresses and credit card information, from Users when they voluntarily provide such information to us, including, for example, when a User signs up to join our email list, purchases tickets to an event, completes the contact form, responds to a job posting, or donates money via the Website. Complete credit card information, however, is not stored. 
How Dēmos Uses the Information that it Collects
Dēmos uses Users’ personal information to better understand how Users use the Website so that we can improve the Website and its offerings. Dēmos also uses Users’ personal information for the purpose for which the Users provided the information, in other words, for example, to add you to our email list, to process your request for tickets to an event, to respond to your inquiry, to consider you for a job, or to process your donation. If you  affirmatively opt-in to our email list, we will add you to our list. If at any time you wish to stop receiving emails from us, please follow the unsubscribe instructions at the bottom of any email Demos sends you.
How Dēmos Protects User Information
Dēmos adopts appropriate data collection, storage, and processing practices and security measures to help protect against unauthorized access, alteration, disclosure or destruction of your personal information, but Dēmos cannot guarantee that your information is 100% secure. Your credit card information is not stored by Dēmos.
Sharing Personal Information.
How Dēmos Protects User Information
Dēmos adopts appropriate data collection, storage, and processing practices and security measures to help protect against unauthorized access, alteration, disclosure or destruction of your personal information, but Dēmos cannot guarantee that your information is 100% secure. Your credit card information is not stored by Dēmos.
Sharing Personal Information
How Dēmos Protects User Information
Dēmos adopts appropriate data collection, storage, and processing practices and security measures to help protect against unauthorized access, alteration, disclosure or destruction of your personal information, but Dēmos cannot guarantee that your information is 100% secure. Your credit card information is not stored by Dēmos.
Sharing Personal Information
How Dēmos Protects User Information
Dēmos adopts appropriate data collection, storage, and processing practices and security measures to help protect against unauthorized access, alteration, disclosure or destruction of your personal information, but Dēmos cannot guarantee that your information is 100% secure. Your credit card information is not stored by Dēmos.
Sharing Personal Information
How Dēmos Protects User Information
Dēmos adopts appropriate data collection, storage, and processing practices and security measures to help protect against unauthorized access, alteration, disclosure or destruction of your personal information, but Dēmos cannot guarantee that your information is 100% secure. Your credit card information is not stored by Dēmos.
Sharing Personal Information
      </p>
    </div>
   </div>
  </div>
  )
}

export default PrivacyPolicy