import React from 'react'
import './LogoSlider.scss'
import Slider from 'react-slick'

function LogoSlider() {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
    }
  return (
    <div className="LogoSlider">
        <div className="container">
        <Slider className='Slider' {...settings}>
            <div className="item">
                <img src="/assets/Home/LogoSlider/logoslider1.png" alt="" />
            </div>
            <div className="item">
                <img src="/assets/Home/LogoSlider/logoslider2.png" alt="" />
            </div>
            <div className="item">
                <img src="/assets/Home/LogoSlider/logoslider3.png" alt="" />
            </div>
            <div className="item">
                <img src="/assets/Home/LogoSlider/logoslider4.png" alt="" />
            </div>
            <div className="item">
                <img src="/assets/Home/LogoSlider/logoslider5.png" alt="" />
            </div>
        </Slider>
        </div>
    </div>
  )
}

export default LogoSlider