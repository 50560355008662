import React from 'react'
import Logo from '../../../Global/Logo/Logo'
import NavBar from './Components/NavBar/NavBar'
import './MiddleBar.scss'
function MiddleBar() {
  return (
    <div className="MiddleBar">
        <div className="row">
            <div className="col-2 col-sm-2">
                <Logo/>
            </div>
            <div className="col-10 col-sm-10 navbar">
               <NavBar/>
            </div>
        </div>
    </div>
  )
}

export default MiddleBar