import React from 'react'
import './About3.scss'
import { Button } from '@mui/material'
import Logo from '../../../../Components/Global/Logo/Logo'
function About3() {
  return (
    <div className="About3">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-5">
                    <div className="imgBox">
                        <img src='/assets/Home/DJP_2254 2.png' alt='' />
                        <img src='/assets/Home/DJP_2301 2.png' alt='' className='right' />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="content-box">
                        <Logo type={"bg"} />
                        <h5 className="title">Innovation</h5>
                        <div className="content ">
                            <p>To create value for our clients through providing innovative  latest technology products and services for their healthcare  delivery by guaranteeing reliable and Consistent supplies,  and providing after sale service for our wide range of medical devices</p>
                            <Button className='primaryBtn mt-3'>See More</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About3