import React from 'react'
import './Header.scss'
import TopBar from './Components/TopBar/TopBar'
import MiddleBar from './Components/MiddleBar/MiddleBar'
function Header() {
  return (
    <header className="header">
      <TopBar/>
      <MiddleBar/>
    </header>
  )
}

export default Header