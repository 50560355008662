import React from "react";
import "./Warehouse.scss";
import BreadCrumb from "../../Components/Global/BreadCrumb/BreadCrumb";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

function Warehouse() {
  const [index, setIndex] = React.useState(-1);
  const images = [
    {
      src: "/assets/Gallery/Warehouse/imagine pharma15.jpg",
    },
    {
      src: "/assets/Gallery/Warehouse/imagine pharma16.jpg",
    },
    {
      src: "/assets/Gallery/Warehouse/imagine pharma17.jpg",
    },
    {
      src: "/assets/Gallery/Warehouse/imagine pharma18.jpg",
    },
    {
      src: "/assets/Gallery/Gallery1.png",
    },
    {
      src: "/assets/Gallery/Gallery5.png",
    },
    {
      src: "/assets/Gallery/Gallery2.png",
    },
    {
      src: "/assets/Gallery/Gallery3.png",
    },
    {
      src: "/assets/Gallery/Gallery4.png",
    },
   
    {
      src: "/assets/Gallery/Gallery6.png",
    },
    {
      src: "/assets/Gallery/Gallery7.png",
    },
  ];

  //  handleClick
  const handleClick = (val) => {
    setIndex(val);
  };

  return (
    <div className="Warehouse">
      <BreadCrumb
        img={"/assets/BreadCrumb/DJP_2238 1.png"}
        title={"WareHouse"}
      />
      <h1 className="headingTitle">Warehouse</h1>
      <div className="container mt-3">
        <div className="contentBox">
          <p>
            Imagine Pharma’s state-of-the-art warehousing facilities,
            strategically located Kampala (Uganda). Our distribution network comprises 9 Good Warehouse Practice
            (GWP)-compliant warehouses and over 8 wholesale outlets, ensuring
            efficient and reliable supply chain management for pharmaceutical
            products.
          </p>
        </div>
        {/* Gallery */}
        <div className="gallery">
          <Gallery
            images={images}
            onClick={handleClick}
            enableImageSelection={false}
          />

          <Lightbox
            index={index}
            slides={images}
            open={index >= 0}
            close={() => setIndex(-1)}
          />
        </div>
      </div>
    </div>
  );
}

export default Warehouse;
