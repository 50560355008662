import React from 'react'
import './HomeSlider.scss'
import Slider from "react-slick";

function HomeSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <div className="HomeSlider">
        <Slider {...settings} className='slider'>
            <div className="item">
                <img src='/assets/HomeBanner/imagine-pharma12.jpg' alt=''/>
            </div>
            <div className="item">
                <img src='/assets/HomeBanner/imagine-pharma14.jpg' alt=''/>
            </div>
            <div className="item">
                <img src='/assets/HomeBanner/imagine-pharma13.jpg' alt=''/>
            </div>
        </Slider>
    </div>
  )
}

export default HomeSlider