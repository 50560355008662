import React from 'react'
import './Map.scss'

function Map() {
  return (
    <div className="Map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7580341056223!2d32.571017!3d0.3149711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbde67881438b%3A0x34683b27842cfbd6!2sImagine%20Pharmacy!5e0!3m2!1sen!2sin!4v1722444916186!5m2!1sen!2sin"
            width={"100%"}
            height={350}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title='map'
        />

    </div>
  )
}

export default Map