import React from "react";
import './BlogLayout.scss'
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
function BlogLayout({img,title,link,cat,catlink,content,date}) {
  return (
    <div className="BlogLayout">
        <div className="topBox" style={{background:`url('${img}')`}}>
            <Button className="catBtn">{cat}</Button>
            <div className="content">
                <p>{content}</p>
                <span className="date">{date}</span>
            </div>
        </div>
        <div className="contentBox">
            <Link><h1 className="title">{title}</h1></Link>
            <Link className="btnReadmore">READ MORE</Link>
        </div>
    </div>
  );
}

export default BlogLayout;
