import React from 'react'
import './About2.scss'

function About2() {
  return (
    <div className="About2">
        <div className="container">
        <div className="row">
            <div className="col-md-7">
                <div className="content-box">
                    <h4 className="title">RESEARCH AND DEVELOPMENT</h4>
                    <div className="content">
                        <p>Imagine Pharma intends to invest heavily in R&D capabilities including dossier development, chemical synthesis, process optimization, formulation development, analytical development and conducting stability studies. We are currently mobilizing a team  of over 20 experienced scientists specializing in formulation development and analytical areas. Together with the technology transfers, we intend to build end-to-end capabilities –  from Lab scale development to Pilot scale up and from commercializing the product  for large scale manufacturing to marketing. .d We intend to forge relationships with other R&D companies globally that have  achieved global recognition to quickly scale our expansion plans for the near future.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-5">
                <div className="imgBox">
                    <img src='/assets/Home/imagine pharma1.png' className='left' alt=''/>
                    <img src='/assets/Home/imagine pharma2.png' className='right' alt=''/>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default About2