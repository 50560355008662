
import React from 'react'
import './AboutHomeWidget.scss'
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import Logo from '../../../../Components/Global/Logo/Logo';

function AboutHomeWidget() {
  return (
    <div className="AboutHomeWidget">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-5">
                    <div className="imgBox">
                        <img src="/assets/Home/DJP_2419 1.png" alt="" />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="aboutmsg">
                        <FaQuoteLeft className='leftQuate'/>
                        <FaQuoteRight className='rightQuate'/>
                        <Logo type={"bg"} className={"bgLogo"}/>
                        <div className="content">
                            <p>
                                <b>Imagine Pharmaceuticals Limited (Imagine Pharma)</b> is a Ugandan registered company with Imagine Design and Build Limited as the parent company. "The essence  of our culture is to build best practices that meet global standards in every aspect  of our business." We aspire to be the Biggest in active Manufacturing, R&D and Distribution of IVDs,  Medical supplies, Preventive and Palliative care medicines and devices that meet  the needs of the region.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutHomeWidget