import React from "react";
import "./Counter.scss";
import CountUp from 'react-countup';

const dataList = [
  {
    img: "/assets/Home/icons/first-aid-kit 1.png",
    title: "M+",
    number:450,
    subtitle: "Distributors of Marie Stopes Uganda and DKT Health care Uganda",
  },
  {
    img: "/assets/Home/icons/earth.svg",
    title: "+",
    number:1000,
    subtitle: "Customers Country wide",
  },
  {
    img: "/assets/Home/icons/working-time 1.png",
    title: "+",
    number:5,
    subtitle: "Years in Operation",
  },
  {
    img: "/assets/Home/icons/employee 1.png",
    title: "+",
    number:30,
    subtitle: "Entire Country wide Network and supplies",
  },
];
function Counter() {


  return (
    <div
      className="Counter"
      style={{
        background: "url('/assets/Home/DJP_2288 1.png'),rgba(10, 65, 82, 0.85)",
      }}
    >
      <div className="container">
        <div className="BoxWrapper">
          <div className="row">
            {dataList?.map((val, index) => (
              <div className="col-md-3 col-sm-12" key={index}>
                <div className="box">
                  <img src={val.img} alt="" />
                  {/* <h4 className="title"><CountUp end={val.number} duration={5}/>{val.title}</h4> */}
                  <p className="subtitle mt-3 " style={{fontSize:"16px",textAlign:"center"}}>{val.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counter;
