import React from 'react'
import './PageNotFound.scss'
function PageNotFound() {
  return (
    <div className="PageNotFound" >
      <div className="container">
      <div className="content">
        <div className="number">404</div>
        <h1 className="title">Page Not Found</h1>
        <p className="subtitle">We are sorry but the page you are looking for does not exist.</p>
        <img src="/assets/PageNotFound/clock.gif" draggable={false} alt="" />
      </div>
      </div>
    </div>
  )
}

export default PageNotFound